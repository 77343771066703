.artist-comp {
  border: 4px solid rgb(22, 22, 22);
  background-color: #0000009f;
  width: 155px;
  margin: 2px;
  padding: 10px;
  text-align: left;
  display: grid;
  grid-template-rows: auto auto;
  flex-direction: column;
  justify-items: start;
  border-radius: 5%;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: inherit;
}

.m-artist-comp {
  border: 2px solid rgb(22, 22, 22);
  background-color: #0000009f;
  width: 105px;
  margin: 2px;
  padding-left: 5px;
  text-align: left;
  display: grid;
  grid-template-rows: auto auto;
  flex-direction: column;
  text-justify: start;
  border-radius: 5%;
  font-size: 0.45em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: inherit;
}
.artist-comp-mp {
  border: 1px solid rgb(255, 255, 255);
  background-color: #000000;
  margin: 5px;
  padding: 15px;
  text-align: left;
  display: grid;
  grid-template-rows: auto auto;
  flex-direction: column;
  justify-items: start;
  border-radius: 5%;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: inherit;
  width: 155px;
  height: 270px;
}
.m-artist-comp-mp{
  border: 2px solid rgb(255, 255, 255);
  background-color: #000000;
  margin: 5px;
  padding: 10px;
  text-align: left;
  display: grid;
  grid-template-rows: auto auto;
  flex-direction: column;
  justify-items: start;
  border-radius: 5%;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: inherit;
  width: 90px;
  height: 155px;
}
.artist-comp-content {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 260px;
}
.m-artist-comp-content {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 5px;
  height: 160px;
}
.dj-page-artist-desc{
  white-space: wrap;
  max-width: 155px;
  font-size: 0.8em;
}
.m-dj-page-artist-desc{
  white-space: wrap;
  max-width: 105px;
}
.artist-desc{
    margin-left: 45px;
}
.m-artist-desc{
    margin-left: 45px;
}
.link {
    color: inherit;
    text-decoration: none;
  }
.link:hover {
    text-decoration: none;
    color: inherit;
  }
.artist-art{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.m-artist-art{
    border-radius: 50%;
    width: 90px;
    height: 90px;
}
.artist-comp-page {
  position: fixed;
  top: 125px;
  background-color: #0000009f;
  width: 98%;
  height: auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
  
}
.m-artist-comp-page{
  position: fixed;
  top: 75px;
  margin-bottom: 40px;
  padding-top: 50px;
  margin-top: 25px;
  background-color: #0000009f;
  width: 95%;
  max-height: 1em;
  max-width: 100%;
  padding: 10px;
  font-size: 1.4em;
  overflow-x: scroll;
  overflow-y: hidden;
}
.artist-comp-content-page {
  font-size: 15px;
  display: flex; 
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.m-artist-comp-content-page{
  width: 100%;
  font-size: 0.7em;
  align-items: left;
  max-height: 100px;
}
.artist-desc-page{
  font-size: 1em;
  margin-top: 220px;
  position: absolute;
  margin-left: 272px;
  max-width: 60%;
}
.m-artist-desc-page{
  font-size: 1em;
  top: 145px;
  position: absolute;
  margin-left: 120px;
  margin-bottom: 20px;
}
.artist-desc-page-text{
  max-height: 200px;
  
  overflow-x: hidden; 
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.m-artist-desc-page-text{
  margin-top: 0px;
  max-height: 150px;
  margin-left: 5%;
  max-width: 85%;
  overflow-x: hidden; 
  overflow-y: scroll;
}
#artist-art-page{
  position: absolute;
  left: 0px;
  margin-top: 220px;
  margin-left: 50px;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
#m-artist-art-page{
  position: absolute;
  left: 5px;
  top: 155px;
  margin-left: 5px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}
.episode-art{
  position: absolute;
  top:69px;
  border-radius: 10%;
  width: 200px;
  height: 200px;
  margin-left: 25px;

}
.episode{
  position: relative;
  display: flex;
  flex-direction: column;
  left:20px;
  margin-bottom: 20px;
  width: 93%;
  background-color: #0000005d;
  border-radius: 5%;
}
.episodes-list{
  margin-top: 490px;
}
.m-episodes-list{
  margin-top: 340px;
}
.m-episode-art{
  position: absolute;
  border-radius: 10%;
  width: 100px;
  height: 100px;
  margin-left: 11px;
  top:40px;

}
.m-episode{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left:10px;
  margin-bottom: 10px;
  width: 90%;
  background-color: #0000005d;
  border-radius: 4%;
}
.episode-timedata{
  position: absolute;
  top:250px;
  left: 35px;
}
.episode-desc{
  height: 220px;
  left: 255px;
  position: relative;
  max-width: 65%;
  min-width: 10%;
  top:-20px;
  overflow-x: hidden; 
  overflow-y: scroll;
  font-size: 0.9em;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.m-episode-desc{
  height: 110px;
  left: 120px;
  position: relative;
  max-width: 60%;
  top: 0px;
  font-size: 0.6em;
  overflow-x: hidden; 
  overflow-y: scroll;
}
#episode-title{
  margin-left: 35px;
  overflow-x: hidden;
  overflow-y: hidden;
}
#m-episode-title{
  margin-left: 10px;
  font-size: 0.8em;
  overflow-x: hidden;
}
.p-search-bar{
  position: absolute;
  left:200px;
  top:160px;
}
.m-p-search-bar{
  position: absolute;
  left:175px;
  top:110px;
}
.artist-page-icons-comp{
  background-color: #00000049;
  position: absolute;
  top: 444px;
  padding-left: 260px;
  width: 67.6%;
  max-height: 38px;
  font-size: 2em;
  overflow-y: hidden; 
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.artist-page-icons{
  margin-right: 15px;
  padding: 1px;
}
.m-artist-page-icons-comp {
  background-color: #00000049;
  position: absolute;
  top: 295px;
  overflow-y: hidden;
  overflow-x: scroll;
  font-size: 1.41em;
  width: 100%;
  max-height: 33px;
  display: inline-flex;
  border-radius: 2%;
}
.m-artist-page-icons{
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left:15px;
}
#ap-share-icon{
  position: fixed;
  top:158px;
  right: 60px;
  font-size: 2em;
}
#m-ap-share-icon{
  margin-right: 10px;
  margin-left: 5px;
  position: fixed;
  top:112px;
  right: 35px;
  font-size: 1.4em;
}
.link-icons {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
}