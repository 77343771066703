body {
  overflow-x: hidden; 
  overflow-y: scroll;
  width: 100%;
  height: auto;
  background: url('psdream.jpg') center/cover fixed; /* This line is modified */
  min-height: 100vh;
  font-family: 'Orbitron', sans-serif;
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  justify-content: center;
  align-items: center;
}

body::-webkit-scrollbar {
  display: none;
}
.body, .body-mobile {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.header {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 500;
  font-size: 1em;
  height: 150px;
}
.header-mobile {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 500;
  font-size: 1em;
  height: 100px;
}
.title-header-mobile {
  font-size: 0.8em;
}
.time-display-mobile {
  display: none;
}
.social-icons {
  font-size: 1.5em;
  width: 100%;
  height: 0px;
  margin-top: 150px;
  background-image: linear-gradient(to bottom, #000000, #0000009f);
  z-index: 0;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.social-icons.open {
  height: 250px;
}
.social-icons-mobile {
  width: 100%;
  height: 0px;
  margin-top: 100px;
  background-image: linear-gradient(to bottom, #000000, #0000009f);
  z-index: 999;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.social-icons-mobile.open {
  height: 230px;
}
.main-menu {
  position: fixed;
  left: 0px;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, width 0.7s ease-in-out;
  height: 100%;
  background-image: linear-gradient(to right, #0000009f, #000000f1);
}

.main-menu.open {
  opacity: 1;
  visibility: visible;
  width: 230px;
}
.main-menu-mobile {
  position: fixed;
  left: 0px;
  top: 100px;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, width 0.5s ease-in-out;
  height: 100%;
  background-image: linear-gradient(to left, #0000009f, #000000f1);
}

.main-menu-mobile.open {
  opacity: 1;
  visibility: visible;
  width: 50%;
}
.main-menu-line {
  max-height: 55px;
  overflow-y: hidden;
}
.m-main-menu-line {
  font-size: 0.6em;
  max-height: 20px;
  overflow-y: hidden;
}
.dropdown-content {
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.icons-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  text-align: center;
  gap: 10%;
  justify-content: center;
  z-index: 1;
}
.apb-barres {
  font-size: 5em;
  color: white;
  display: flex;
  position: fixed;
  left: 25px;
  top: 27px;
  gap: 3em;
}
.apb-barres-mobile {
  font-size: 2.8em;
  color: white;
  display: flex;
  position: fixed;
  left: 10px;
  top: 22px;
  gap: 3em;
}
.apb-gear {
  font-size: 2em;
  color: white;
  display: flex;
  position: fixed;
  right: 15px;
  top: 154px;
  gap: 3em;
}
.apb-gear-mobile {
  font-size: 1.4em;
  color: white;
  display: flex;
  position: fixed;
  right: 5px;
  top: 109px;
  gap: 3em;
}
.apb-gear-menu {
  font-size: 3.4em;
  color: white;
  display: flex;
  position: fixed;
  right: 15px;
  top: 154px;
  gap: 3em;
}
.apb-gear-menu-mobile {
  font-size: 3.5em;
  color: white;
  display: flex;
  position: fixed;
  right: 5px;
  top: 109px;
  gap: 3em;
}
.apb-user{
  font-size: 3em;
  color: white;
  display: flex;
  position: fixed;
  right: 50px;
  top: 50px;
  gap: 3em;
}
.apb-user-mobile {
  font-size: 2em;
  color: white;
  display: flex;
  position: fixed;
  right: 20px;
  top: 32px;
  gap: 3em;
}
.apb-insta {
  font-size: 3em;
  color: white;
}
.apb-discord {
  font-size: 2.7em;
  color: white;
  position: relative;
  top: 5%;
}
.apb-github {
  font-size: 2.9em;
  color: white;
  position: relative;
  top: 2.5%;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 500;
  display: grid;
  height: 150px;
}
.footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 600;
  display: grid;
  height: 100px;
}
.metadata {
  position: fixed;
  text-align: left;
  left: 200px;
  bottom: 10px;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.metadata-mobile {
  position: fixed;
  text-align: left;
  left: 20%;
  bottom: 10px;
  font-size: 0.65em;
  width: 50%;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}
#lecteurAudio {
  display: none;
}
.custom-progress {
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: fixed;
  text-align: center;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}
#progress-bar {
  height: 100%;
  background-color: #00bcd4;
  width: 0;
  transition: width 0.5s ease-in-out;
}
.custom-progress-mobile {
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: fixed;
  text-align: center;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
#cover-np-song {
  border: 10px;
  position: fixed;
  text-align: left;
  left: 1px;
  bottom: -10px;
  width: 120px;
  padding: 20px;
  height: auto;
  border-radius: 15%;
}
#cover-np-song-mobile {
  border: 10px;
  position: fixed;
  text-align: left;
  right: 1px;
  bottom: 12px;
  width: 75px;
  padding: 5px;
  height: auto;
  border-radius: 10%;
}
.playlist {
  position: fixed;
  text-align: center;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1500;
}
.playlist-mobile {
    position: fixed;
    text-align: center;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8em;
    z-index: 1500;
}
.next-song-container, .volume-control-container, .audio-buttons-container, .gear-menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 0.1s ease-in-out;
  font-size: 0.4em;
}

.next-song-container.visible, .volume-control-container.visible, .audio-buttons-container.visible, .gear-menu.visible {
  opacity: 1;
  visibility: visible;
}

.next-song-display, .m-next-song-display, .volume-control, .volume-control-mobile, .audioButton, .audioButton-mobile {
  display: block; /* Ensure these are block elements to take up space when visible */
}
.next-song-container.visible{
  font-size: 0.4em;
  background-color: #000000;
}
.m-next-song-display{
  font-size: 1em;
  position: fixed;
  top: 290px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 100%;
}
.m-nxsg-playlist{
  display: none;
}

.default-bitrate{
  background-color: #00bcd4;
  width:30%;
  height: 40px;
}
.c1{
  background-color: #00bcd4;
  width:30%;
  height: 40px;
  position: relative;
  bottom:40px;
}
.c2{
  background-color: #00bcd4;
  width:30%;
  height: 40px;
  position: relative;
  bottom:40px;
}
.c3{
  background-color: #00bcd4;
  width:30%;
  height: 40px;
  position: relative;
  bottom:40px;
}
.audioButton{
  font-size: 1.4em;
  position: fixed;
  top: 300px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 100%;
}
.audioButton-mobile{
  font-size: 1.4em;
  position: fixed;
  top: 175px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width:100%;
}
.default-bitrate-mobile{
  background-color: #00bcd4;
  width:30%;
  height: 40px;
}
.mc1{
  background-color: #00bcd4;
  width:30%;
  position:relative;
  bottom:40px;
  height: 40px;

}
.mc2{
  background-color: #00bcd4;
  width:30%;
  position:relative;
  bottom:40px;
  height: 40px;
}
.mc3{
  background-color: #00bcd4;
  width:30%;
  position:relative;
  bottom:40px;
  height: 40px;

}
#muteVol{
  font-size: 0.7em;
  border: 10px;
  color: #fff;
  background-color: black;
  position: fixed;
  right: 275px;
  bottom: 90px;
  height:20px;
}
#muteVol-mobile{
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color:#ffffff;
  position: relative;
  bottom:5px;
  right: 1px;
}
.volume-control{
  display: none;
}
.volume-control-mobile{
  display: flex;
  position: fixed;
  top:260px;
  align-items: flex-start;
  /*text-align: center;*/
  font-size: 2em;
  left:20%;
}
.volume-control2{
  position: fixed;
  right: 60px;
  font-size: 2em;
  bottom: 50px;
}
.volume-control-mobile2{
  display: none;
}
#playButton{
  font-size: 4em;
  position: fixed;
  bottom: 35px;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  text-align: center;
  color:#ffffff;
  background-color: black;
  border: none;
  left: 50%;
  transform: translateX(-50%);
}
#playButtonMobile{
  font-size: 2em;
  position: fixed;
  bottom: 28px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  color : #ffffff;
  background-color: black;
  border: none;
  left: 10%;
  transform: translateX(-50%);
  z-index: 1001; 
}
#ScheduleLeftColumn{
  position: relative;
  left: 4%;
}
#ScheduleRightColumn{
  position: relative;
  right: 4%;
}
.scheduleTable{
  width: 80%;
  position: relative;
  margin:auto;
  margin-top: 75px;
  font-size: 1.3em;
  margin-left: 10%;
  text-align: center;
}
.m-scheduleTable{
  width: 96%;
  position: relative;
  margin:auto;
  margin-top: 95px;
  font-size: 1.3em;
  margin-left: 2%;
  text-align: center;
}
.table-bbg{
  padding-top: 20px;
  background-image: linear-gradient(to top, #00000010, #4127886c);
  border-radius: 5%;
}
.table-bbg-1{
  padding-top:20px;
  padding-bottom: 10px;
  background-image: linear-gradient(to top, #000000, #0000006c);
  border-radius: 5%;
  margin:auto;
}
.m-table-bbg{
  background-image: linear-gradient(to top, #00000010, #4127886c);
  border-radius: 5%;
}
.m-table-bbg-1{
  padding-bottom: 10px;
  background-image: linear-gradient(to top, #000000, #0000006c);
  border-radius: 5%;
  margin:auto;
}

.header-table{
  background-color: black;
  border: 1px solid rgb(255, 255, 255);
  padding: 4px;
  text-align: center;
  margin:auto;
}
.header-table th, .header-table td {
  padding: 8px
}
.body-table{
  
  background-color: black;
  border: 5px solid rgb(255, 255, 255);
  padding: 4px;
  text-align: center;
  margin:auto;
}
.bg-centre{
  margin-top: 20px;
  margin-bottom: 50px;
  width: 90%;
  background-color: #0000008e;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px) grayscale(0.5) opacity(0.2);
  justify-content: center;
  align-items: center;
}
.bg-centre-mobile{
  font-size: 0.6em;
  margin-top: 0px;
  margin-bottom: 0px ;
  width: 100%;
  background-color: #0000008e;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px) grayscale(0.5) opacity(0.2);
  justify-content: center;
  align-items: center;
}
.p-endpage{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 170px;
  font-size: 0.8em;
}
.m-p-endpage{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  font-size: 0.8em; 
  margin-bottom: 120px;
}
.m-endpage-fix{
  padding: 10px;
  font-size: 0.6em;
}
.bresil{
  display: none;
}

.history-song {
  position: fixed;
  top: 150px;
  width: 60%;
  left: 20%;
  background-color: #0000009f;
  z-index: 0;
  scrollbar-width: none;
  transition: opacity 0.5s ease-in-out, visibility 0.2s ease-in-out;
}
.history-song-mobile {
  font-size:0.8em;
  position: fixed;
  top: 100px;
  width: 70%;
  left: 15%;
  background-color: #0000009f;
  z-index: -1000;
  bottom:125px;
  scrollbar-width: none;
  transition: opacity 0.5s ease-in-out, visibility 0.2s ease-in-out;
}
.song-history-entry {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.song-details {
  margin-left: 15px;
}
.song-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.song-artist {
  font-style: italic;
}
.img-history {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
}
.img-history-mobile {
  width: 75px;
  height: auto;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
}
.np-display{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.black-textborder{
  text-shadow:
  1px 1px 0 #000,
  -1px 1px 0 #000,
  1px -1px 0 #000,
  -1px -1px 0 #000,
  0px 1px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000,
  1px 0px 0 #000,
  2px 2px 0 #000,
  -2px 2px 0 #000,
  2px -2px 0 #000,
  -2px -2px 0 #000,
  0px 2px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000,
  2px 0px 0 #000,
  1px 2px 0 #000,
  -1px 2px 0 #000,
  1px -2px 0 #000,
  -1px -2px 0 #000,
  2px 1px 0 #000,
  -2px 1px 0 #000,
  2px -1px 0 #000,
  -2px -1px 0 #000;
  -moz-text-shadow:
  1px 1px 0 #000,
  -1px 1px 0 #000,
  1px -1px 0 #000,
  -1px -1px 0 #000,
  0px 1px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000,
  1px 0px 0 #000,
  2px 2px 0 #000,
  -2px 2px 0 #000,
  2px -2px 0 #000,
  -2px -2px 0 #000,
  0px 2px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000,
  2px 0px 0 #000,
  1px 2px 0 #000,
  -1px 2px 0 #000,
  1px -2px 0 #000,
  -1px -2px 0 #000,
  2px 1px 0 #000,
  -2px 1px 0 #000,
  2px -1px 0 #000,
  -2px -1px 0 #000;
  -webkit-text-shadow:
  1px 1px 0 #000,
  -1px 1px 0 #000,
  1px -1px 0 #000,
  -1px -1px 0 #000,
  0px 1px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000,
  1px 0px 0 #000,
  2px 2px 0 #000,
  -2px 2px 0 #000,
  2px -2px 0 #000,
  -2px -2px 0 #000,
  0px 2px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000,
  2px 0px 0 #000,
  1px 2px 0 #000,
  -1px 2px 0 #000,
  1px -2px 0 #000,
  -1px -2px 0 #000,
  2px 1px 0 #000,
  -2px 1px 0 #000,
  2px -1px 0 #000,
  -2px -1px 0 #000;
}