.h-djsets{
  margin-top: 79px;
  background-color: rgba(0, 0, 0, 0.719);
  padding-top: 50px;
  height: 70px;
  margin-bottom: 5px;
}
.m-h-djsets{
  margin-top: 100px;
  background-color: rgba(0, 0, 0, 0.719);
  height: 50px;
}

.main-djsets {
  position: relative;
  top: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.m-main-djsets {
    position: relative;
    top: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.last-release-mp{
  position: relative;
  top: 10%;
  width: 80%;
  left: 6%;
  background-image: linear-gradient(to top left, #0000008f, #000000f1);
  margin: 5px;
  padding: 10px;
  border-radius: 5%;
  margin-top: 50px;
  margin-bottom: 75px;
}
.m-last-release-mp{
  position: relative;
  top: 10%;
  width: 90%;
  left: 1%;
  background-image: linear-gradient(to top left, #0000008f, #000000f1);
  margin: 5px;
  padding: 10px;
  border-radius: 5%;
  margin-top: 10px;
  margin-bottom: 30px;
}
.main-djsets-mp-container{
  overflow-x: scroll; 
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 95%;
  margin-left:5%;
  margin-right:5%;
  max-height: 350px;
}
.m-main-djsets-mp-container{
  overflow-x: scroll;
  overflow-y: hidden; 
  white-space: nowrap;
  max-width: 95%;
  max-height: 200px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;

}
.main-djsets-mp {
  position: relative;
  top: 0px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap; 
}
.m-main-djsets-mp {
  position: relative;
  top: 0px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: -8%;
  margin-right: 8%; 
}